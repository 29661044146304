<template>
    <div>
        <hero/>
        <contents/>
    </div>
</template>

<script>
    export default {
        components: {
            Hero: () => import ('@/components/agenda/Hero'),
            Contents: () => import('@/components/agenda/Contents')
        },
        mounted(){
            this.$store.dispatch('app/setLink', 1)
            },
        created() {

        }
    }
</script>
